import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { PosterNamesEnum } from 'src/client/common/poster/poster-names.enum'
import { StaticPageProps } from 'src/client/common/static-page-server-side-props/@types/static-page-props'
import { getStaticPageServerSideProps } from 'src/client/common/static-page-server-side-props/get-static-page-server-side-props'
import { translate } from 'src/client/common/translatable/translatable'
import { LoginForm } from 'src/client/modules/my-account/login/login-form'
import CookieBar from 'src/client/modules/static-pages/cookie-bar/cookie-bar.component'
import Footer from 'src/client/modules/static-pages/footer/footer.component'
import MyNavbar from 'src/client/modules/static-pages/my-navbar/my-navbar-component'
import { createLink, Routes } from 'src/client/routes'

import styles from './login.module.scss'

export const getServerSideProps = getStaticPageServerSideProps

const Login: NextPage<StaticPageProps> = ({ isLoggedIn, allowCookie, discountData }) => {
  const router = useRouter()

  const onForceClose = () => {
    router.replace(createLink(Routes.Pages_Home))
  }
  const onViewChange = () => {
    router.replace(createLink(Routes.Pages_ResetPassword))
  }

  return (
    <>
      <Head>
        <link rel="canonical" href="https://piesotto.pl/o-piesotto" />
        <title>{translate('O nas - Piesotto')}</title>
        <meta
          name="description"
          content="Piesotto to personalizowane i dostarczane do domu jedzenie dla Twojego psa. Jakość, smak i eksperckie wsparcie. Dowiedz się więcej o naszej ofercie!"
        />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Strona główna'
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: 'O nas',
                  item: 'https://piesotto.pl/o-piesotto'
                }
              ]
            })
          }}
        />
      </Head>

      <div className="bg-stripes-horizontal">
        <MyNavbar isLoggedIn={isLoggedIn} activeTabIndex={3} discountData={discountData} />

        <div className={styles.loginDialog}>
          <LoginForm onForceClose={onForceClose} onViewChange={onViewChange} />
        </div>
      </div>

      <Footer
        hasNewsletter
        posterName={PosterNamesEnum.About}
        posterImgAlt="naturalne jedzenie dla psów piesotto"
      />

      <CookieBar allowCookie={Boolean(allowCookie)} />
    </>
  )
}

export default Login
